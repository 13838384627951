<template>
    <div class="edu-frame" style="background-color: #cde7f7;">
        <edu-top-header></edu-top-header>
        <div class="edu-group" style="margin: 20px auto;">
            <div class="step-tips">请选择身份：</div>
            <div class="guides">
                <div class="guides-btn" @click="toLogin(1, 'single')">
                    <div class="guides-btn-logo-frame"><img class="guides-btn-logo" src="../../statics/images/old-glass.png"></div>
                    <div class="guides-btn-label">南粤巾帼园（老人大学）</div>
                </div>
                <div class="guides-btn" @click="toLogin(2, 'single')">
                    <div class="guides-btn-logo-frame"><img class="guides-btn-logo" src="../../statics/images/child.png"></div>
                    <div class="guides-btn-label">少儿培训</div>
                </div>
                <div class="guides-btn" @click="toLogin(4, 'single')">
                  <div class="guides-btn-logo-frame"><img class="guides-btn-logo" src="../../statics/images/adult.png"></div>
                  <div class="guides-btn-label">幸福夜校</div>
                </div>
                <div class="guides-btn" @click="toLogin(3, 'single')">
                    <div class="guides-btn-logo-frame"><img class="guides-btn-logo" src="../../statics/images/activity.png"></div>
                    <div class="guides-btn-label">活动报名</div>
                </div>
<!--                 <div class="guides-btn" @click="toLogin(1, 'childGroup')">
                  <div class="guides-btn-logo-frame"><img class="guides-btn-logo" src="../../statics/images/organizations.png"></div>
                  <div class="guides-btn-label">少儿社团</div>
                </div>-->
            </div>
        </div>

        <hr class="split-line"/>
        <div class="edu-group" style="margin: 20px auto;">
            <div class="step-tips">注册：</div>
            <div class="guides">
                <div class="guides-btn" @click="$router.push({ name: 'register' })">
                    <div class="guides-btn-logo-frame"><img class="guides-btn-logo" src="../../statics/images/stu.png"></div>
                    <div class="guides-btn-label">少儿新学员注册</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import EduTopHeader from "@/components/EduHeader";
    import Tools from '@/api/Tools'
    import EduConstants from "@/api/EduConstants";
    import CustomerApi from "@/api/CustomerApi";
    export default {
        components: {EduTopHeader},
        name: 'index',
        data() {
            return {
                loading: false
            }
        },
        methods: {
            toLogin(loginType, identityType) {
                Tools.setIdentityType(identityType)
                Tools.addSession(EduConstants.key_loginType, loginType)
                if (loginType == 3) {
                  this.$router.push({ name: 'activitys' })
                  return
                }

                var openId = this.$route.query.openid
                // console.log(openId)
                if (openId == undefined || openId == null ||openId == '') {
                  if (loginType == 4) {
                    this.$router.push({ name: 'loginforadult' })
                  } else {
                    this.$router.push({ name: 'login' })
                  }
                } else {
                  Tools.addSession('openId', openId)
                  this.loading = true
                  CustomerApi.loginByOfficalOpenId({ weixinOpenId: openId, loginType: loginType, identityType: identityType }).then(response=>{
                    // console.log(response.res)
                    this.loading = false
                    if (response.res != null) {
                      Tools.setCurCust(response.res)
                      Tools.setToken(response.res.token)

                      this.$router.replace({name: 'main'})
                    } else {
                      if (loginType == 4) {
                        this.$router.push({ name: 'loginforadult' })
                      } else {
                        this.$router.push({ name: 'login' })
                      }
                    }

                  }).catch(()=>{ this.loading = false })
                }
            }
        },
        mounted() {
          var openId = this.$route.query.openid
          if (openId != undefined && openId != null && openId != '') {
            sessionStorage.setItem('openId', openId)
          }
          var isreuploadavatar = this.$route.query.isreuploadavatar // 是否需要重新上传
          if (isreuploadavatar != null && isreuploadavatar != undefined && isreuploadavatar != '') {
            sessionStorage.setItem('isreuploadavatar', isreuploadavatar)
          }
        }
    }
</script>
<style scoped>


    .guides {
        width: 90%;
        padding: 0 2%;
        margin: 10px auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .guides-btn {
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        margin: 7px 0px;
        border: 2px solid white;
        box-shadow: 2px 5px 4px lightgray;
        /*background-color: white;*/
        color: white;
        font-weight: bold;
        border-radius: 8px;
        background-image: linear-gradient(to right, deepskyblue , #1989fa);
    }

    .guides-btn-logo-frame {
        position: absolute;
        left: 10%;
        height: 100%;
        display: flex;
        align-items: center;
    }

    .guides-btn-logo {
        width: 30px;
        height: 30px;
    }

    .guides-btn-label {
        display: flex;
        justify-content: center;
    }
    .split-line {
        background-color: lightgray;
        height: 1px;
        width: 95%;
        border: 0px;
    }
</style>
